import React from 'react';

const FooterCopyright = () => {
  const year = new Date().getFullYear();
  return (
    <div className="FooterCopyright">
      <div className="Container">
        {/* <p>
          <a href={`https://www.briz.ua${i18n.language === 'ua' ? '' : '/ru'}`}>
            <span>{t('provider')}</span> BRIZ.TV
          </a>
        </p> */}
        <p>{`Copyright © ${year} BRIZ.TV. All rights reserved.`}</p>
      </div>
    </div>
  );
};

export default React.memo(FooterCopyright);
