import { client } from './api-client';

// Stream
export const tvFetchChannelsStream = (channel_id, signal) =>
  client.get(`tv/stream?channel_id=${channel_id}`, signal);

// All channels grouped by genres
export const tvFetchChannelsGrouped = () =>
  client.get(`v2/tv/channels-grouped`, {
    headers: { Accept: 'application/vnd.briz.default.v2+json' }
  });

// Epg + recorded (catchup)
export const tvFetchChannelEpg = (channel_id, from, to, signal) => {
  const _from = from ? `&from=${from - 1}` : '';
  const _to = to ? `&to=${to}` : '';
  return client.get(`v2/tv/epg?channel_id=${channel_id}${_from}${_to}`, signal);
};
export const tvFetchChannelCatchup = (channel_id, from, to) => {
  const _from = from ? `&from=${from}` : '';
  const _to = to ? `&to=${to}` : '';
  return client.get(`tv/stream-catchup?channel_id=${channel_id}${_from}${_to}`);
};

// Favorites
export const tvFetchFavoriteChannels = () => client.get(`tv/favorites`);
export const tvAddFavoriteChannel = (body) => client.post(`tv/favorites`, body);
export const tvRemoveFavoriteChannel = (body) => client.delete(`tv/favorites`, body);

// Channels by genre
export const tvFetchChannelsByGenre = (genre) => client.get(`tv/genre-channels?genre_id=${genre}`);

// TV channels list
export const tvFetchChannelsList = () => client.get(`tv/channels`);

// TV channels list-popular
export const tvFetchChannelsListPopular = () => client.get(`tv/channels-popular`);

// TV channels categories
export const tvFetchChannelCategories = () => client.get('tv/genres');

export const tvFetchChannelInfoById = (channel_id) =>
  client.get(`tv/channel?channel_id=${channel_id}`);
