import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import Parser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { throttle } from '../../../helpers/utils';

// Assets
import './../Form.scss';
import './LoginForm.scss';

// Form stuff
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import {
  // captchaValidation,
  loginValidation
} from '../../validation';
import { getFormattedLoginValue } from '../../../helpers/utils';

// Components
// import ReCaptcha from '../../form-items/ReCaptcha';
import RegisterExternalUserContainer from '../../../containers/RegisterExternalUserContainer';
import RegisterInternalUserContainer from '../../../containers/RegisterInternalUserContainer';
import AuthModalContainer from '../../../containers/AuthModalContainer';
import TextInputComponent from '../../form-items/TextInputComponent';

// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { getIsRegistrationEnabled } from '../../../../features/video-configuration/videoConfigurationSlice';
import { loginCheck } from '../../../../features/auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';

const LoginForm = ({ onSuccess = () => {} }) => {
  const { t, i18n } = useTranslation();
  const [isInBriz, setIsInBriz] = useState(false);
  const [notInBriz, setNotInBriz] = useState(false);

  // const recaptchaRef = React.createRef();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const isRegistrationEnabled = useSelector(getIsRegistrationEnabled);
  const backgroundLocation = location.state ? location.state.background : '/';
  const goToPasswordForm = ({ login, login_type, registered }) => {
    history.push({
      pathname: '/auth/password',
      state: {
        background: backgroundLocation,
        registered,
        login_type,
        login
      }
    });
  };

  const handleRegisterInternalUserClose = () => {
    setIsInBriz(false);
    history.push(backgroundLocation);
  };

  const handleRegisterExternalUserClose = () => {
    setNotInBriz(false);
    history.push(backgroundLocation);
  };

  const unfreezeField = (e) => {
    const field = e.target.closest('.Form__group').querySelector('input');
    const { value } = field;
    field.value = value;
  };

  // Schema for yup
  const validationSchema = Yup.object({
    login: loginValidation().trim()
    // captcha: captchaValidation
  });

  if (isInBriz) {
    return (
      <RegisterInternalUserContainer
        onModalClose={handleRegisterInternalUserClose}
        modalClassName={'Modal_RegisterInternalUser'}
      />
    );
  }

  if (notInBriz) {
    return (
      <RegisterExternalUserContainer
        onModalClose={handleRegisterExternalUserClose}
        modalClassName={'Modal_RegisterExternalUser'}
      />
    );
  }

  return (
    <AuthModalContainer
      title={t('auth.loginForm.title')}
      socials={isRegistrationEnabled}
      description={Parser(t('auth.loginForm.subtitle'))}
    >
      <Formik
        initialValues={{
          login: ''
        }}
        validationSchema={validationSchema}
        onSubmit={throttle(async (values, { setSubmitting, setErrors }) => {
          try {
            const { data } = await unwrapResult(
              await dispatch(
                await loginCheck({ login: getFormattedLoginValue(values?.login).trim() })
              )
            );
            if (!data.registered && data.internal) {
              setIsInBriz(true);
              return;
            }
            if (!data.registered && !data.internal && !data.registration_enabled) {
              setNotInBriz(true);
              return;
            }
            goToPasswordForm(data);
            onSuccess();
          } catch (err) {
            if (err.message) {
              const errors = JSON.parse(err.message);
              setErrors({ login: errors?.login[0] });
            }
            setSubmitting(false);
          }
        }, 2000)}
      >
        {() => (
          <Form className="Form LoginForm">
            <div className="Form__group">
              <Field
                name="login"
                className={`text-field__input`}
                label={t('auth.loginForm.loginInputPlaceholder')}
                component={TextInputComponent}
                onClick={unfreezeField}
              />
              <div className="i-m-error-wrap">
                <ErrorMessage name="login" component="span" className="i-m-error" />
              </div>
            </div>
            {/* --- ReCaptcha will be here --- */}
            <div className="Form__group">
              <button className="Btn Btn--primary" type="submit">
                {t('parentControlBtnText')}
              </button>
            </div>
            <p className="Form__text Form__text-privacy-note">
              <span>{t('auth.loginForm.agreementText')}</span>
              <br />
              <a href={`/privacy/${i18n.language}`} rel="noreferrer" target="_blank">
                {t('auth.loginForm.agreementLinkText')}
              </a>
            </p>
            {/* <div className="Form__text Form__text-bottom">
              <Link
                className="reg-link"
                to={{
                  pathname: '/auth/register',
                  state: { background: backgroundLocation }
                }}
              >
                {t('auth.loginForm.register')}
              </Link>
              <span>&nbsp; {t('auth.loginForm.newAccount')}</span>
            </div> */}
          </Form>
        )}
      </Formik>
    </AuthModalContainer>
  );
};

LoginForm.propTypes = {
  onSuccess: PropTypes.func
};

export default LoginForm;
